import React, { useState, useEffect } from 'react';
import { useHttpGetAllVbattDevices } from '../../hooks';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import { Input } from '../../components/Common';

const VbattDevicesSafetyWrapper = () => {
    const { data: devices, isReady: devicesAreReady } = useHttpGetAllVbattDevices();

    if (!devicesAreReady) {
        return <Loader />;
    }

    if (!devices.data || devices.data.length === 0) {
        return <NoVbattDevicesFound />;
    }

    return <Devices data={devices.data} />;
};

const Devices = ({ data }) => {
    const history = useHistory();
    const [filters, setFilters] = useState({
        searchTerm: '',
        filterBattery: '',
        filterType: '',
    });
    const [expanded, setExpanded] = useState({});

    const handleFilterChange = (key, value) => {
        setFilters(prev => ({ ...prev, [key]: value }));
    };

    const groupedDevices = data.reduce((acc, device) => {
        const key = device.battery_name || 'Unknown Battery';
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(device);
        return acc;
    }, {});

    useEffect(() => {
        setExpanded(Object.keys(groupedDevices).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
    }, [data]);

    const filteredGroupedDevices = Object.entries(groupedDevices).reduce((acc, [battery, devices]) => {
        const filteredDevices = devices.filter(device => {
            const deviceId = device.device_id || '';
            const deviceType = device.device_type || '';
            const batteryName = device.battery_name || '';

            return (
                (!filters.searchTerm || deviceId.includes(filters.searchTerm)) &&
                (!filters.filterBattery || batteryName.includes(filters.filterBattery)) &&
                (!filters.filterType || deviceType.includes(filters.filterType))
            );
        });

        if (filteredDevices.length > 0) {
            acc[battery] = filteredDevices;
        }
        return acc;
    }, {});

    return (
        <div className="card-wrapper vbatt-devices">
            <h1 className="vbatt-devices__title">VBATT Devices Overview</h1>

            <div className="vbatt-devices__filters">
                {[
                    { key: 'searchTerm', placeholder: 'Search by Device ID' },
                    { key: 'filterBattery', placeholder: 'Filter by Battery Name' },
                    { key: 'filterType', placeholder: 'Filter by Device Type' },
                ].map(({ key, placeholder }) => (
                    <Input type="text" placeholder={placeholder} onChange={e => handleFilterChange(key, e.target.value)} />
                ))}
            </div>

            {Object.entries(filteredGroupedDevices).map(([battery, devices]) => (
                <div key={battery} className="vbatt-devices__content">
                    <p className="vbatt-devices__content--title" onClick={() => setExpanded(prev => ({ ...prev, [battery]: !prev[battery] }))}>
                        {battery}
                        {expanded[battery] ? (
                            <i className="fa-solid fa-sort-up faq__question--opened-question"></i>
                        ) : (
                            <i className="fa-solid fa-sort-down faq__question--down-arrow"></i>
                        )}
                    </p>
                    {expanded[battery] && (
                        <table className="vbatt-devices__table">
                            <thead>
                                <tr>
                                    <th>Price Area</th>
                                    <th>Manufacturer</th>
                                    <th>Device ID</th>
                                    <th>Device Type</th>
                                    <th>Health Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {devices.map((device, index) => (
                                    <tr
                                        key={index}
                                        onClick={() => device.device_id && history.push(`/vbatt-devices/${device.device_id}`)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <td>{device.price_area || 'N/A'}</td>
                                        <td>{device.manufacturer || 'N/A'}</td>
                                        <td>{device.device_id || 'N/A'}</td>
                                        <td>{device.device_type || 'N/A'}</td>
                                        <td className={device.device_health_status && device.device_health_status.toLowerCase()}>
                                            {device.device_health_status || 'N/A'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            ))}
        </div>
    );
};

const NoVbattDevicesFound = () => {
    return (
        <div className="card-wrapper no-vbatts-found">
            <div className="no-devices-message">No Virtual Batteries Found</div>
        </div>
    );
};

export { VbattDevicesSafetyWrapper as VbattDevices };
export default VbattDevicesSafetyWrapper;
