import { Chart } from 'react-google-charts';
const { DateTime } = require('luxon');

const AlertDistribution = ({ alerts }) => {
    const buildDistributionData = (alerts = []) => {
        if (alerts.length === 0) {
            return [['Time', 'CRITICAL', 'ERROR', 'WARNING']];
        }

        let timestamps = alerts.map(alert => DateTime.fromISO(alert.timestamp));
        if (timestamps.length === 0) {
            return [['Time', 'CRITICAL', 'ERROR', 'WARNING']];
        }

        const minDate = timestamps.reduce((min, dt) => (dt < min ? dt : min), timestamps[0]);
        const maxDate = timestamps.reduce((max, dt) => (dt > max ? dt : max), timestamps[0]);

        const groups = {};
        let current = minDate.startOf('hour');
        while (current <= maxDate) {
            const key = current.toFormat('yyyy-MM-dd HH:00');
            groups[key] = { CRITICAL: 0, ERROR: 0, WARNING: 0 };
            current = current.plus({ hours: 2 });
        }

        alerts.forEach(alert => {
            const dateKey = DateTime.fromISO(alert.timestamp)
                .startOf('hour')
                .minus({ minutes: DateTime.fromISO(alert.timestamp).minute % 120 })
                .toFormat('yyyy-MM-dd HH:00');

            if (groups[dateKey] && groups[dateKey][alert.type] !== undefined) {
                groups[dateKey][alert.type] += 1;
            }
        });

        const sortedKeys = Object.keys(groups).sort();
        let data = sortedKeys.map(key => {
            const date = DateTime.fromFormat(key, 'yyyy-MM-dd HH:00').toJSDate();
            let { CRITICAL, ERROR, WARNING } = groups[key];

            return [date, Math.max(CRITICAL, 0), Math.max(ERROR, 0), Math.max(WARNING, 0)];
        });

        return [['Time', 'CRITICAL', 'ERROR', 'WARNING'], ...data];
    };

    const distributionOptions = {
        legend: { position: 'right' },
        curveType: 'none',
        pointSize: 1,
        lineWidth: 2,
        vAxis: { minValue: 0 },
        series: {
            0: { color: '#6e020d' }, // CRITICAL
            1: { color: '#dc3545' }, // ERROR
            2: { color: '#ffc107' }, // WARNING
        },
    };

    return (
        <div className="device-alerts__distribution">
            <h3 className="device-alerts__section-title">Distribution Over Time</h3>
            <div>
                <Chart chartType="LineChart" width="90%" height="300px" data={buildDistributionData(alerts)} options={distributionOptions} />
            </div>
        </div>
    );
};

export default AlertDistribution;
