import { useEffect, useState } from 'react';
import api from '../../api';

const useHttpGetDeviceEvents = device_id => {
    const [isReady, setIsReady] = useState(false);
    const [data, setData] = useState([]);

    const getDeviceLifeEvents = async () => {
        try {
            const events = await api.get_device_life_events(device_id);
            setData(events);
        } catch (error) {
            console.error('Error happened while loading device life events. ', error);
        } finally {
            setIsReady(true);
        }
    };

    useEffect(() => {
        getDeviceLifeEvents();
    }, []);

    return { isReady, data };
};

export default useHttpGetDeviceEvents;
