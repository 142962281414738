import { useEffect, useState } from 'react';
import api from '../../api';

const useHttpGetAllVbattDevices = () => {
    const [isReady, setIsReady] = useState(false);
    const [data, setData] = useState([]);

    const getAllVbattDevices = async () => {
        try {
            const devices = await api.get_vbatt_devices();
            setData(devices);
        } catch (error) {
            console.error('Error happened while loading batteries devices. ', error);
        } finally {
            setIsReady(true);
        }
    };

    useEffect(() => {
        getAllVbattDevices();
    }, []);

    return { isReady, data };
};

export default useHttpGetAllVbattDevices;
