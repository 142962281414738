import React from 'react';
import { useHttpGetHealthReport } from '../../hooks';
import Loader from '../../components/Loader';
import withUrlParam from '../../hocs/withUrlParam';
import { Button } from '../../components/Common';
import AlertSummary from './DeviceAlerts/AlertSummary';
import AlertDistribution from './DeviceAlerts/AlertDistributionOverTime';
import AlertTable from './DeviceAlerts/AlertTable';
import { useHistory } from 'react-router-dom';

const DeviceAlertsSafetyWrapper = ({ paramValue }) => {
    const id = paramValue;
    const { data: healthReport, isReady } = useHttpGetHealthReport(id);

    if (!isReady) {
        return <Loader />;
    }

    const { alerts } = healthReport?.data || {};

    if (!alerts || alerts.length === 0) {
        return <NoAlertsFound deviceId={id} />;
    }

    return (
        <div className="card-wrapper">
            <VBattDeviceAlerts id={id} alerts={alerts} />
        </div>
    );
};

const VBattDeviceAlerts = ({ id, alerts }) => {
    const history = useHistory();

    const navigateToHealth = deviceId => {
        if (!deviceId) {
            return;
        }
        history.push(`/vbatt-devices/${deviceId}/health`);
    };

    return (
        <div className="device-alerts">
            <div className="device-alerts__header">
                <h2>Alerts</h2>
                <Button className="device-alerts__back-button" onClick={() => navigateToHealth(id)} text="Back to Health Report" />
            </div>

            <div className="device-alerts__content">
                <div className="device-alerts__graphs">
                    <AlertSummary alerts={alerts} />
                    <AlertDistribution alerts={alerts} />
                </div>
                <AlertTable alerts={alerts} />
            </div>
        </div>
    );
};

const NoAlertsFound = ({ deviceId }) => {
    const history = useHistory();

    const navigateToHealth = () => {
        if (!deviceId) {
            return;
        }
        history.push(`/vbatt-devices/${deviceId}/health`);
    };
    return (
        <div className="card-wrapper no-alerts">
            <h3>No Alerts Found</h3>
            <p>No alerts have been recorded for this device on its health checks.</p>
            <Button className="device-alerts__back-button" onClick={navigateToHealth} text="Back to Health Report" />
        </div>
    );
};

export default withUrlParam('id')(DeviceAlertsSafetyWrapper);
