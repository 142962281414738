import { Chart } from 'react-google-charts';

const AlertSummary = ({ alerts }) => {
    const buildSummaryData = (alerts = []) => {
        if (alerts.length === 0) {
            return [];
        }
        return [
            ['Status', 'Count'],
            ['CRIT', alerts.filter(a => a.type === 'CRITICAL').length],
            ['ERR', alerts.filter(a => a.type === 'ERROR').length],
            ['WARN', alerts.filter(a => a.type === 'WARNING').length],
        ];
    };

    const summaryOptions = {
        legend: { position: 'right' },
        sliceVisibilityThreshold: 0,
        slices: {
            0: { color: '#6e020d' },
            1: { color: '#dc3545' },
            2: { color: '#ffc107' },
        },
    };

    return (
        <div className="device-alerts__summary">
            <h3 className="device-alerts__section-title">Summary</h3>
            <Chart chartType="PieChart" width="85%" height="300px" data={buildSummaryData(alerts)} options={summaryOptions} />
        </div>
    );
};

export default AlertSummary;
