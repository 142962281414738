const DeviceHealthCard = ({ healthCheck }) => {
    const { details, ok, name } = healthCheck;

    return (
        <div className="health-check-card">
            <div className="health-check-card__title">
                <span className={`status-icon status-icon--${ok ? 'ok' : 'not-ok'}`}>
                    {ok ? <i className="fa fa-check-circle" aria-hidden="true" /> : <i className="fa fa-times-circle" aria-hidden="true" />}
                </span>
                <p className="health-check-name">{name}</p>
            </div>
            <div className="health-check-card__details">
                {details && Object.keys(details).length > 0 ? (
                    <ul>
                        {Object.entries(details).map(([key, value]) => (
                            <li key={key}>
                                <strong>{key}:</strong> {value}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="no-details">No details available</p>
                )}
            </div>
        </div>
    );
};

export default DeviceHealthCard;
