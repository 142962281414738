import { useEffect, useState } from 'react';
import api from '../../api';

const useHttpGetHealthReport = device_id => {
    const [isReady, setIsReady] = useState(false);
    const [data, setData] = useState({});

    const getDeviceHealthReport = async () => {
        try {
            const health = await api.get_device_health_report(device_id);
            setData(health);
            setIsReady(true);
        } catch (error) {
            console.error('Error happened while loading batteries devices. ', error);
        }
    };

    useEffect(() => {
        getDeviceHealthReport();
    }, []);

    return { data, isReady };
};

export default useHttpGetHealthReport;
