import React from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';

function withUrlParam(paramName) {
    return function (WrappedComponent) {
        return function (props) {
            const params = useParams();
            const paramValue = params[paramName];

            if (!paramValue) {
                return <Loader />;
            }
            return <WrappedComponent {...props} paramValue={paramValue} />;
        };
    };
}

export default withUrlParam;
