import React, { useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import Chart from 'react-google-charts';
import Loader from '../../components/Loader';
import { useHttpGetDeviceInfo, useHttpGetDeviceEvents, useHttpGetDeviceDeliveryReports } from '../../hooks';
import { Select } from '../../components/Common';
import withUrlParam from '../../hocs/withUrlParam';

const VbattDeviceSafetyWrapper = ({ paramValue }) => {
    const id = paramValue;
    const deliveryReportsDateTimeRange = {
        from: DateTime.utc().minus({ days: 1 }).toISO(),
        to: DateTime.utc().toISO(),
    };

    const { data: info, isReady: deviceIsReady } = useHttpGetDeviceInfo(id);
    const { data: lifeEvents, isReady: lifeEventsAreReady } = useHttpGetDeviceEvents(id);
    const { data: reports, isReady: reportsAreReady } = useHttpGetDeviceDeliveryReports(
        id,
        deliveryReportsDateTimeRange.from,
        deliveryReportsDateTimeRange.to
    );

    if (!deviceIsReady || !lifeEventsAreReady || !reportsAreReady) {
        return <Loader />;
    }

    const device = { id, info, lifeEvents };

    return (
        <div className="card-wrapper">
            <VirtualBattery device={device} reports={reports} />
        </div>
    );
};

const ConnectionStatus = ({ connected }) => (
    <span className={connected ? 'status-connected' : 'status-disconnected'}>
        <i className={`fa ${connected ? 'fa-check-circle' : 'fa-times-circle'}`} aria-hidden="true" /> {connected ? 'Connected' : 'Disconnected'}
    </span>
);

const MeasurementsDisplay = ({ measurements }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleMainSection = () => {
        setIsExpanded(prev => !prev);
    };

    if (!measurements || Object.keys(measurements).length === 0) {
        return <>N/A</>;
    }

    return (
        <div className="measurements-container">
            <div className="measurement-header main-header" onClick={toggleMainSection}>
                <strong>Measurements</strong>
                <i className={`fa ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
            </div>

            {isExpanded && (
                <div className="measurement-content">
                    <table className="measurement-table">
                        <tbody>
                            {Object.entries(measurements).map(([key, value]) => (
                                <tr key={key}>
                                    <td>
                                        <strong>{key}</strong>
                                    </td>
                                    <td>
                                        {typeof value === 'object' && value !== null ? (
                                            <pre>{JSON.stringify(value, null, 2)}</pre> // Display objects in readable format
                                        ) : value !== null && value !== undefined ? (
                                            value.toString()
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

const VirtualBattery = ({ device, reports }) => {
    const history = useHistory();
    const infoData = device?.info?.data || {};
    const lifeCycleData = device?.lifeEvents?.data || [];
    const reportData = reports?.data || [];
    const marketCapacities = infoData.market_capacities || {};
    const [selectedDeliveryReportMetric, setSelectedDeliveryReportMetric] = useState('average_power');

    const chartData = useMemo(() => {
        if (!reportData || reportData.length === 0) {
            return [['Time', selectedDeliveryReportMetric]];
        }
        return [
            ['Time', selectedDeliveryReportMetric],
            ...reportData.map(report => [
                new Date(report.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                report[selectedDeliveryReportMetric] || 0,
            ]),
        ];
    }, [reportData, selectedDeliveryReportMetric]);

    const options = useMemo(
        () => ({
            curveType: 'function',
            legend: { position: 'none' },
            hAxis: {
                title: 'Time',
                textStyle: { fontSize: 12, color: '#555' },
                titleTextStyle: { fontSize: 14, bold: true },
            },
            vAxis: {
                title: selectedDeliveryReportMetric.replace('_', ' '),
                textStyle: { fontSize: 12, color: '#555' },
                titleTextStyle: { fontSize: 14, bold: true },
            },
            colors: ['#ff7b00'],
            lineWidth: 3,
            pointSize: 5,
            backgroundColor: 'transparent',
            chartArea: { width: '85%', height: '70%' },
        }),
        [selectedDeliveryReportMetric]
    );

    const sortedLifeCycleData = useMemo(() => {
        return [...lifeCycleData].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }, [lifeCycleData]);

    const eventsModifier = sortedLifeCycleData.length === 1 ? 'vbatt-device__events-list--single' : '';

    const navigateToDevices = () => {
        history.push(`/vbatt-devices`);
    };

    return (
        <div className="vbatt-device">
            <i className="fa-solid fa-arrow-left link-icon devices-health__back-icon" onClick={() => navigateToDevices()}></i>
            <div className="vbatt-device__grid-row">
                <div className="vbatt-device__card">
                    <h2 className="vbatt-device__card-title">Device Info</h2>
                    <p className="vbatt-device__card-text">
                        <strong>Device ID:</strong> {infoData.id || 'N/A'}
                    </p>
                    <p className="vbatt-device__card-text">
                        <strong>Virtual Battery Name:</strong> {infoData.vbatt_name || 'N/A'}
                    </p>
                    <p className="vbatt-device__card-text">
                        <strong>Price Area:</strong> {infoData.price_area || 'N/A'}
                    </p>
                    <p className="vbatt-device__card-text">
                        <strong>Manufacturer:</strong> {infoData.manufacturer || 'N/A'}
                    </p>
                    <p className="vbatt-device__card-text">
                        <strong>Product Type:</strong> {infoData.product_type || 'N/A'}
                    </p>
                    <p className="vbatt-device__card-text">
                        <strong>Connection Status:</strong> <ConnectionStatus connected={infoData.connected} />
                    </p>
                    <p className="vbatt-device__card-text">
                        <strong>Last Updated:</strong> {infoData.updated ? new Date(infoData.updated).toLocaleString() : 'N/A'}
                    </p>
                    <div className="vbatt-device__card-text">
                        <div className="vbatt-device__measurements">
                            <MeasurementsDisplay measurements={infoData.measurements} />
                        </div>
                    </div>
                    {device.id && (
                        <div>
                            <Link to={`/vbatt-devices/${device.id}/health`} className="vbatt-device__btn">
                                Device Health Status
                            </Link>
                        </div>
                    )}
                </div>

                <div className="vbatt-device__card">
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        {Object.keys(marketCapacities).length > 0 && (
                            <div>
                                <h2 className="vbatt-device__card-title">Market Capacities</h2>
                                <table className="vbatt-device__markets-table">
                                    <thead>
                                        <tr>
                                            <th>Market</th>
                                            <th>Capacity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {marketCapacities.fcr_n !== undefined && (
                                            <tr>
                                                <td>FCR-N</td>
                                                <td>{(marketCapacities.fcr_n * 100).toFixed(0)}%</td>
                                            </tr>
                                        )}
                                        {marketCapacities.fcr_d_up !== undefined && (
                                            <tr>
                                                <td>FCR-D Up</td>
                                                <td>{(marketCapacities.fcr_d_up * 100).toFixed(0)}%</td>
                                            </tr>
                                        )}
                                        {marketCapacities.fcr_d_down !== undefined && (
                                            <tr>
                                                <td>FCR-D Down</td>
                                                <td>{(marketCapacities.fcr_d_down * 100).toFixed(0)}%</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div>
                            <h2 className="vbatt-device__card-title">Lifecycle Events</h2>
                            {sortedLifeCycleData.length === 0 ? (
                                <p className="vbatt-device__card-text">No events found.</p>
                            ) : (
                                <div className={`vbatt-device__events-list ${eventsModifier}`}>
                                    {sortedLifeCycleData.map((eventItem, index) => (
                                        <div className="vbatt-device__event-item" key={index}>
                                            <p className="vbatt-device__event-title">
                                                <strong>{new Date(eventItem.timestamp).toLocaleString()}:</strong> {eventItem.event}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="vbatt-device__card">
                <h2 className="vbatt-device__card-title">Delivery Reports</h2>

                {reportData.length === 0 ? (
                    <p style={{ marginTop: '20px' }}>No delivery reports found for selected period.</p>
                ) : (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <label htmlFor="metricSelect" className="vbatt-device__label">
                                Select Metric:
                            </label>
                            <Select
                                className={'vbatt-device__select'}
                                options={[
                                    { value: 'average_power', label: 'Average Power' },
                                    { value: 'max_power', label: 'Max Power' },
                                    { value: 'min_power', label: 'Min Power' },
                                    { value: 'average_soc', label: 'Average SoC' },
                                    { value: 'charged_energy', label: 'Charged Energy' },
                                    { value: 'discharged_energy', label: 'Discharged Energy' },
                                ]}
                                value={selectedDeliveryReportMetric}
                                onChange={e => setSelectedDeliveryReportMetric(e.target.value)}
                            />
                        </div>
                        <Chart chartType="LineChart" width="100%" height="300px" data={chartData} options={options} />
                    </>
                )}
            </div>
        </div>
    );
};

export { VbattDeviceSafetyWrapper as VbattDevicePage };
export default withUrlParam('id')(VbattDeviceSafetyWrapper);
