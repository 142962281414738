const AlertTable = ({ alerts }) => {
    return (
        <div className="device-alerts__table-container">
            <table className="device-alerts__table">
                <thead>
                    <tr>
                        <th>Timestamp</th>
                        <th>Type</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {alerts.map(alert => (
                        <tr key={alert.timestamp}>
                            <td>{new Date(alert.timestamp).toLocaleString()}</td>
                            <td className={`alert-type device-alerts--${alert.type.toLowerCase()}`}>{alert.type}</td>
                            <td>{alert.message}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AlertTable;
