import { useEffect, useState } from 'react';
import api from '../../api';

const useHttpGetDeviceDeliveryReports = (device_id, from, to) => {
    const [isReady, setIsReady] = useState(false);
    const [data, setData] = useState([]);

    const getDevicesDeliveryReports = async () => {
        try {
            const deliveryReport = await api.get_devices_delivery_report(device_id, from, to);
            setData(deliveryReport);
        } catch (error) {
            console.error('Error happened while loading devices delivery reports. ', error);
        } finally {
            setIsReady(true);
        }
    };

    useEffect(() => {
        getDevicesDeliveryReports();
    }, []);

    return { data, isReady };
};

export default useHttpGetDeviceDeliveryReports;
