import React from 'react';
import withUrlParam from '../../hocs/withUrlParam';
import Loader from '../../components/Loader';
import { useHttpGetHealthReport } from '../../hooks';
import DeviceHealthCard from './DeviceHealth/DeviceHealthCard';
import { useHistory } from 'react-router-dom';

const formatDate = dateString => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    }).format(date);
};

const VbattDeviceHealthReportSafetyWrapper = ({ paramValue }) => {
    const deviceId = paramValue;
    const { data: healthReport, isReady: healthReportAreReady } = useHttpGetHealthReport(deviceId);

    if (!healthReportAreReady) {
        return <Loader />;
    }

    const response = healthReport.data;
    const health = response.health;
    const alerts = response.alerts ?? [];

    if (!health) {
        return <NoHealthReport deviceId={deviceId} />;
    }

    const from = new Date(health.start);
    const to = new Date(health.end);

    const findLastCheckAlerts = (allAlerts, fromDate, toDate) => {
        return allAlerts.filter(alert => {
            const alertFrom = new Date(alert.details.from);
            const alertTo = new Date(alert.details.to);
            return alertFrom >= fromDate && alertTo <= toDate;
        });
    };

    const lastAlerts = findLastCheckAlerts(alerts, from, to);

    return <DeviceHealth health={health} lastAlerts={lastAlerts} />;
};

const DeviceHealth = ({ health, lastAlerts }) => {
    const history = useHistory();

    const navigateToAlerts = deviceId => {
        if (!deviceId) {
            return;
        }
        history.push(`/vbatt-devices/${deviceId}/alerts`);
    };

    const navigateToDevice = deviceId => {
        if (!deviceId) {
            return;
        }
        history.push(`/vbatt-devices/${deviceId}`);
    };

    return (
        <div className="card-wrapper devices-health">
            <i className="fa-solid fa-arrow-left link-icon devices-health__back-icon" onClick={() => navigateToDevice(health.device_id)}></i>
            <div className="devices-health__header">
                <div className="devices-health__header-main">
                    <h3 className="devices-health__header-title">Last Health Report</h3>
                    <p className="devices-health__header-status">
                        <span>Status: </span>
                        <span className={`status-text status-text--${health.status.toLowerCase()}`}>{health.status}</span>
                    </p>
                </div>
                <div className="devices-health__header-dates">
                    Monitoring Period: {formatDate(health.start)} - {formatDate(health.end)}
                </div>
            </div>

            <div className="devices-health__content">
                <div className="devices-health__content-checks">
                    <div className="devices-health__content-checks-title">Health Checks</div>
                    <div className="devices-health__content-checks-list">
                        {health.health_checks.map(check => (
                            <DeviceHealthCard key={check.name} healthCheck={check} />
                        ))}
                    </div>
                </div>

                <div className="devices-health__content-checks">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className="devices-health__content-checks-title">Alerts</div>
                        <div className="devices-health__content-checks-title--link" onClick={() => navigateToAlerts(health.device_id)}>
                            See Recent Alerts History
                        </div>
                    </div>

                    <div className="devices-health__content-checks-list devices-health__content-checks-list--no-border-bottom">
                        {lastAlerts.length > 0 ? lastAlerts.map((alert, index) => <AlertCard key={index} alert={alert} />) : <NoAlerts />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const AlertCard = ({ alert }) => {
    return (
        <div className="alert-card">
            <div className="alert-card__content">
                <p className="alert-card__message">
                    <strong>Message:</strong> {alert.message}
                </p>
            </div>
        </div>
    );
};

const NoHealthReport = ({ deviceId }) => {
    const history = useHistory();
    const navigateToDevice = deviceId => {
        if (!deviceId) {
            return;
        }
        history.push(`/vbatt-devices/${deviceId}`);
    };

    const navigateToAlerts = deviceId => {
        if (!deviceId) {
            return;
        }
        history.push(`/vbatt-devices/${deviceId}/alerts`);
    };
    return (
        <div className="card-wrapper devices-health">
            <i className="fa-solid fa-arrow-left link-icon devices-health__back-icon" onClick={() => navigateToDevice(deviceId)}></i>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>No health report available for this device.</p>
                <div className="devices-health__content-checks-title--link" onClick={() => navigateToAlerts(deviceId)}>
                    See Recent Alerts History
                </div>
            </div>
        </div>
    );
};

const NoAlerts = () => {
    return (
        <div className="no-alerts-fallback">
            <p className="no-alerts-fallback__message">Alerts not found found on this health report.</p>
        </div>
    );
};

export default withUrlParam('id')(VbattDeviceHealthReportSafetyWrapper);
