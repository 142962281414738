import React from 'react';
import WebViewTheme from './WebViewTheme';

const CustomerWebView = ({ retailerId }) => {
    return (
        <div className="retailer-api retailer-api__customer-settings">
            <div>
                <h2 className="retailer-api__title">Customer Web View</h2>
                <p className="retailer-api__desc">
                    The Emulate-provided web view is the easiest way to integrate. Your backend web application signs a{' '}
                    <a href="https://jwt.io">JWT</a> to both authenticate yourself to Emulate and provide details of your customer and their
                    facilities (locations that have a meter). Then, you can leave the rest to us!
                </p>
            </div>

            <div>
                <h4 className="retailer-api__subtitle">Theme</h4>
                <p className="retailer-api__desc">
                    You can configure the color scheme and fonts for the web app. Feel free to skip this step and jump to the integration instructions
                    below, you can come back and change these theme settings any time.
                </p>
            </div>
            <div className="customer-web-view__img">
                <img src="/assets/icons/appview.png" alt="" />
            </div>

            <div className="customer-web-view__theme-wrapper">
                <WebViewTheme />
            </div>

            <div>
                <h4 className="retailer-api__subtitle">Key Setup</h4>
                <p className="retailer-api__desc">
                    First, you need a RSA 256 keypair (public and private key). You will keep the private part to yourself, and use it to sign the
                    JWT. <strong>This must be kept in your backend system and never leaked.</strong> Emulate use the public part of the key to verify
                    that the JWT was really singed by you.
                </p>
                <p className="retailer-api__desc">
                    You may generate a keypair yourself, or Emulate can generate one for you. Emulate discards the private part, so only you have it.
                    However, if you want to further decrease risk, you might prefer to produce your own. Visit the Key Management tab in order to
                    generate or upload a key. The key must be registered for the <strong>Single Customer API</strong>.
                </p>
            </div>

            <div>
                <h4 className="retailer-api__subtitle">JWT</h4>
                <p className="retailer-api__desc">The JWT body should have the following content:</p>
                <code>
                    <pre className="retailer-api__code">{`{
    // The audience (a fixed string indicating the JWT is for Emulate; you
    // should send it exactly as shown here.)
    "aud": "https://api.emulate.network/",
    // Your Emulate retailer ID (also to be sent as presented here)
    "retailer": ${retailerId},
    // An expiration time for this JWT, in seconds since the UNIX epoch.
    // Optional, but recommended, otherwise the token is valid forever.
    "exp": 1649773792,
    // Your ID for your customer. This is how you will be able to look up
    // the customer in the retailer dashboard. Emulate considers it an
    // opaque token; it must simply be unique for each of your customers.
    "customer": "23421",
    // An array of at least one facility (location where the customer has a
    // meter).
    "facilities": [
        {
        // Your ID for the customer's facility. Again, opaque to Emulate,
        // must be unique among all of your facilities.
        "id": "ABC1323",
        // The address of the facility.
        "address": "Sankt Petri kyrkogata 7, 222 21 Lund, Sweden",
        // If known, the coordinates of the facility. Optional; if not
        // provided, the address will be geocoded.
        "location": {
            "lat": 55.7063455,
            "long": 13.1913016
        },
        // The price area of the facility (for example, SE1)
        "price_area": "SE3",
        // The ID of the power grid that the facility is on (for
        // example, MMO). Optional.
        "grid_id": "LND"
        }
    ]
}`}</pre>
                </code>
            </div>

            <div>
                <h4 className="retailer-api__subtitle">Entrypoint URL</h4>
                <p className="retailer-api__desc">
                    To produce a link to the web view for a particular customer, first form the JWT. Then construct a URL of the form:
                </p>
                <code>
                    <pre>https://customer.emulate.network/?code=TOKEN</pre>
                </code>
                <p>
                    Replacing <code>TOKEN</code> with the generated JWT.
                </p>
            </div>

            <div>
                <h4 className="retailer-api__subtitle">Node.js Example</h4>
                <p className="retailer-api__desc">
                    Uses the <code>jsonwebtoken</code> package.
                </p>
                <code>
                    <pre className="retailer-api__code">{`const jwt = require('jsonwebtoken');
                
let token = jwt.sign(
    {
        aud: 'https://api.emulate.network/',
        exp: (new Date().getTime() / 1000) + 24 * 60 * 60, // 24 hours
        retailer: ${retailerId},
        // Replace literal values below with real customer ata
        customer: "23421",
        "facilities": [
            {
                "id": "ABC1323",
                "address": "Sankt Petri kyrkogata 7, 222 21 Lund, Sweden",
                // Remove location if you don't have this information
                "location": {
                    "lat": 55.7063455,
                    "long": 13.1913016
                },
                "price_area": "SE3",
                // Remove grid ID if you don't have this information.
                "grid_id": "LND"
            }
        ]
    },
    yourPrivateKey,
    { algorithm: 'RS256'}
);

let url = \`https://customer.emulate.network/?code=\${token}\`;
`}</pre>
                </code>
            </div>
        </div>
    );
};

export default CustomerWebView;
