import { useEffect, useState } from 'react';
import api from '../../api';

const useHttpGetDeviceInfo = device_id => {
    const [isReady, setIsReady] = useState(false);
    const [data, setData] = useState({});

    const getDeviceInfo = async () => {
        try {
            const device = await api.get_retailers_device_info(device_id);
            setData(device);
        } catch (error) {
            console.error('Error happened while loading device data. ', error);
        } finally {
            setIsReady(true);
        }
    };

    useEffect(() => {
        getDeviceInfo();
    }, []);

    return { isReady, data };
};

export default useHttpGetDeviceInfo;
