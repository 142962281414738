import 'core-js/full'; // for set operations polyfill

import Overview from './containers/Overview/Overview';
import RetailerAPI from './containers/RetailerAPI/RetailerAPI';
import Support from './containers/Support/Support';
import AllDevices from './containers/AllDevices/AllDevices';
import RetailerReporting from './containers/RetailerReporting/RetailerReporting';
import UserManagement from './containers/UserManagement/UserManagement';
import NoAccountFunctionality from './containers/ErrorStates/NoAccountFunctionality';
import CustomerDetails from './containers/DevicesDetail/CustomerDetails';
import SearchCustomers from './containers/DevicesDetail/SearchCustomers';
import Batteries from './containers/Batteries/Batteries';
import BatteryDetails from './containers/BatteryDetails/BatteryDetails';
import ControlSessionDetails from './containers/BatteryDetails/ControlSessionDetails';
import Announcements from './containers/Announcements';
import VbattDevicePage from './containers/VirtualBatteries/Device';
import VbattDevices from './containers/VirtualBatteries/VbattDevices';
import VbattDeviceHealth from './containers/VbattDevices/VbattDeviceHealth';
import VBattDeviceAlerts from './containers/VbattDevices/VBattDeviceAlerts';

export default function getRoutes(availableRoles) {
    const { availableRoutes } = ROUTES.reduce(
        (routes, route) => {
            if (routes.routesSeen.has(route.path)) {
                throw new Error(`duplicate route registered: ${route.path}`);
            }

            const isAllowed = hasAtLeastOneMatchingRole(availableRoles, route.allowedRoles);
            if (isAllowed) {
                routes.availableRoutes.push(route);
                routes.routesSeen.add(route.path);
            }

            return routes;
        },
        { routesSeen: new Set(), availableRoutes: [] }
    );

    if (availableRoutes.length < 1) {
        // Catch-all landing page, only shown if the account has no matching roles
        availableRoutes.push({
            path: '/no-account-functionality',
            component: NoAccountFunctionality,
        });

        console.error(
            `no known routes available for account with ` +
                `retailer role(s): '${[...availableRoles.retailer]}' ` +
                `or brp role(s): '${[...availableRoles.brp]}'`
        );
    }

    return availableRoutes;
}

// RE `allowedRoles`:
// Currently a route is able to specify per-realm roles that should grant an account
// access. If an account has _at least_ one role (for the matching realm), then
// that is considered sufficient. This does mean that we cannot currently express
// more sophisticated matches, like `retailer.reporting` && `retailer.customer_data_read`
const ROUTES = [
    {
        path: '/devices',
        component: Overview,
        allowedRoles: {
            retailer: new Set(['customer_data_read']),
            brp: new Set([]),
        },
    },
    {
        path: '/devicesdetail/:id',
        component: CustomerDetails,
        allowedRoles: {
            retailer: new Set(['customer_data_read']),
            brp: new Set([]),
        },
    },
    {
        path: '/devicesdetail',
        component: SearchCustomers,
        allowedRoles: {
            retailer: new Set(['customer_data_read']),
            brp: new Set([]),
        },
    },
    {
        path: '/retailer-api',
        component: RetailerAPI,
        allowedRoles: {
            retailer: new Set(['api_management']),
            brp: new Set([]),
        },
    },
    {
        path: '/support',
        component: Support,
        allowedRoles: {
            retailer: new Set(['customer_data_read']),
            brp: new Set([]),
        },
    },
    {
        path: '/all-devices',
        component: AllDevices,
        allowedRoles: {
            retailer: new Set(['customer_data_read']),
            brp: new Set([]),
        },
    },
    {
        path: '/retailer-reporting',
        component: RetailerReporting,
        allowedRoles: {
            retailer: new Set(['reporting']),
            brp: new Set([]),
        },
    },
    {
        path: '/user-management',
        component: UserManagement,
        allowedRoles: {
            retailer: new Set(['user_management']),
            brp: new Set([]),
        },
    },
    {
        path: '/vbatt-devices',
        component: VbattDevices,
        allowedRoles: {
            retailer: new Set(['customer_data_read', 'customer_data_write', 'api_management', 'reporting', 'user_management']),
            brp: new Set(['battery_manage']),
        },
    },
    {
        path: '/vbatt-devices/:id',
        component: VbattDevicePage,
        allowedRoles: {
            retailer: new Set(['customer_data_read', 'customer_data_write', 'api_management', 'reporting', 'user_management']),
            brp: new Set(['battery_manage']),
        },
    },
    {
        path: '/vbatt-devices/:id/health',
        component: VbattDeviceHealth,
        allowedRoles: {
            retailer: new Set(['customer_data_read', 'customer_data_write', 'api_management', 'reporting', 'user_management']),
            brp: new Set(['battery_manage']),
        },
    },
    {
        path: '/vbatt-devices/:id/alerts',
        component: VBattDeviceAlerts,
        allowedRoles: {
            retailer: new Set(['customer_data_read', 'customer_data_write', 'api_management', 'reporting', 'user_management']),
            brp: new Set(['battery_manage']),
        },
    },
    {
        path: '/batteries',
        component: Batteries,
        allowedRoles: {
            retailer: new Set([]),
            brp: new Set(['battery_read']),
        },
    },
    {
        path: '/batteries/:id',
        component: BatteryDetails,
        allowedRoles: {
            retailer: new Set([]),
            brp: new Set(['battery_read']),
        },
    },
    {
        path: '/batteries/:id/sessions/:sessionId',
        component: ControlSessionDetails,
        allowedRoles: {
            retailer: new Set([]),
            brp: new Set(['battery_read']),
        },
    },
    {
        path: '/announcements',
        component: Announcements,
        allowedRoles: {
            retailer: new Set(['customer_data_read', 'reporting', 'user_management', 'api_management']),
            brp: new Set(['battery_read']),
        },
    },
];

function hasAtLeastOneMatchingRole(availableRoles, allowedRoles) {
    const matchingRetailerRoles = availableRoles.retailer.intersection(allowedRoles.retailer);
    const matchingBrpRoles = availableRoles.brp.intersection(allowedRoles.brp);
    // comparing the role matches per-realm here. If either realm has a match then return true.
    return matchingRetailerRoles.size > 0 || matchingBrpRoles.size > 0;
}
